export const getBUSDAddress = () => {
    return "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
};

export const getMMProAddress = () => {
    return "0x6067490d05F3cF2fdFFC0e353b1f5Fd6E5CCDF70";
};

export const getAllocationMarketplaceAddress = () => {
    return "0x7846CA06f58Ce7669AacdCeF87FE739Cb873ca3d";
};

export const getPancakeRouterAddress = () => {
    return "0x10ed43c718714eb63d5aa57b78b54704e256024e";
};