export default {
  MetaMaskDisconnectMessage: {
    en: 'Please disconnect using MetaMask',
    ja: 'MetaMaskを使用して切断してください'
  },
  Disconnect: {
    en: 'Disconnect',
    ja: '切断する'
  },
  ConnectWallet: {
    en: 'Connect Wallet',
    ja: 'ウォレットを接続する'
  }
}